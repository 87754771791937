import React from "react";
import Footer from "../home/footer/Footer";
import Navbar from "../home/navbar/Navbar";
import AboutUs from "./AboutUs";
import AboutUsHead from "./AboutUsHead";
import SEO from "../Seo";
import * as styles from "./aboutUs.module.css";

const Index = () => {
  return (
    <div className={styles.componentWrapper}>
      <SEO titleStub={`About`} urlStub={`/about/`} />
      <Navbar />
      <AboutUsHead />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default Index;
