import React from "react";
import * as styles from "./aboutUs.module.css";

const AboutUsHead = () => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h1>
            <span>About @sleeplessyogi</span>
          </h1>
          <h3>You are in for a ride...</h3>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHead;
