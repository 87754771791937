import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import * as styles from "./aboutUs.module.css";

const AboutUs = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <h2>Why listen to me? Or read my articles?</h2>
        <p>No. This gig is not my fulltime income.</p>
        <p>
          In fact, I have a 9-5 job that puts a bread on our table at home. I
          just happened to like helping entrepreneurs like you achieve their
          side hustle dreams without sacrificing their 9-5 jobs!
        </p>
        <p>
          "Sleepless" Yogi - as the name suggests, I am an ambitious,
          determined, driven, and insatiably curious to reach (my) potential [as
          humanly possible]
        </p>
        <br />
        <p>And...</p>
        <p>
          Here I am with over 10 years of experience as a Software Engineer and
          5 years of experience as an entrepreneur - achieving my ultimate goal
          of earning passive income streams. By leveraging my programming
          skills.
        </p>
        <br />
        <h2>The story goes beyond that...</h2>
        <p>I have a firm belief in giving back what I have learned</p>
        <p>
          Wherefore, I have successfully laid the foundation to share my systems
          with you and help you in building your dream empire from scratch
        </p>
        <br />
        <p>Yes, you read this right!</p>
        <p>
          I will help and equip you to reach the highest level of your creative
          potential and generate desired income from (zero cost) $0!
        </p>
        <p>
          Through{" "}
          <Link
            to="https://ngninja.gumroad.com/"
            target="_blank"
            title="free content, online training programs, and weekly personal
            access via online chat"
          >
            free content, online training programs, and weekly personal access
            via online chat
          </Link>{" "}
          - I am determined to help you build your splendid info-products,
          online shops, and freelancing careers.
        </p>
        <br />
        <h2>How on earth will that happen?</h2>
        <p>Easy enough!</p>
        <p>
          I have{" "}
          <Link
            to="https://ngninja.gumroad.com/l/bizBook/blog"
            target="_blank"
            title="Make Money on Instagram"
          >
            created a Bootcamp
          </Link>{" "}
          to provide you with a step-by-step roadmap that includes skillful
          tactics and strategies - the ones I used to create potential
          info-products; that have helped me generate a steady passive income.
        </p>
        <p>
          The road to the Holy Grail has been designed for you. Don't miss it.
        </p>
        <p>When you want it, you make it happen!</p>
        <p>So, what's the delay?</p>
        <p>
          ENROLL YOURSELF TODAY to achieve your dream life, one-of-a-kind and
          brilliantly tailored for you.
        </p>
        <p>No more hustle of a 9-5 job.</p>
        <br />
        <h2>How?</h2>
        <p>
          This Bootcamp will help to drive yourself with purpose and align
          yourself with excellence.
        </p>
        <p>
          In other words, a little dedication and determination are all you need
          to set forth from the rest.
        </p>
        <br />
        <p>
          So do not wait any longer to jump on the train where you can earn
          income from multiple streams.
        </p>
        <p>
          If you have scrolled down to this part, then let's stay connected.
        </p>
        <p>
          Tell me about yourself on{" "}
          <a
            href="mailto:sleeplessyogi@gmail.com"
            target="_blank"
            title="Email"
          >
            sleeplessyogi@gmail.com
          </a>
        </p>
        <p>
          And, follow me on Instagram and Twitter -{" "}
          <a
            href="https://twitter.com/sleeplessyogi"
            target="_blank"
            title="Email"
          >
            @sleeplessyogi
          </a>
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
